import { Container } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const AdminPage = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        let accessCode = queryParams.get("code");
        if (accessCode !== null) {
            sessionStorage.setItem("accessToken", accessCode);
            navigate("/admin");
        } else {
            accessCode = sessionStorage.getItem("accessToken");
            if (accessCode === null) {
                navigate("/home");
            }
        }
    });

    return (
        <Container>
            <h1>AdminPage</h1>
        </Container>
    )
}

export default AdminPage;