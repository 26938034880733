import { Container, Nav } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "react-bootstrap";

const NavBar = () => {
    const isAuthenticated = () => {
        const accessToken = sessionStorage.getItem("accessToken");
        return !!accessToken;
    };

    const navigate = useNavigate();

    return (
        <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
            <Container>
                <Navbar.Brand>
                    <Nav.Link href="" onClick={() => navigate("/home")}>
                        Trading Bot Tracker
                    </Nav.Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="" onClick={() => navigate("/home")}>Home</Nav.Link>
                        {isAuthenticated() ? (
                            <section id="logged-in">
                                <Nav.Link href="" onClick={() => navigate("/admin")}>Admin</Nav.Link>
                                <Nav.Link href="https://tradingbot.auth.us-east-2.amazoncognito.com/logout?client_id=7pt7vmsi3r6242cpulamg7945&logout_uri=https%3A%2F%2Fquillow.io%2Fhome">Log Out</Nav.Link>
                            </section>
                        ) : (<Nav.Link href="https://tradingbot.auth.us-east-2.amazoncognito.com/login?client_id=7pt7vmsi3r6242cpulamg7945&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fquillow.io%2Fadmin">Login</Nav.Link>)}
                        <Nav.Link href="https://trader.tradovate.com/welcome" target="_blank">Tradovate</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar;