import React, { useEffect, useState } from 'react';
import './App.css';
import {
    ChartComponent,
    SeriesCollectionDirective,
    SeriesDirective,
    CandleSeries,
    DateTime,
    Tooltip,
    Zoom,
    Crosshair,
    Inject,
    TooltipSettingsModel,
    ScrollBar,
    Legend,
    StripLine,
    AxisModel,
    StripLineSettingsModel,
    ZoomSettingsModel
} from '@syncfusion/ej2-react-charts';
import { AttributeValue } from "@aws-sdk/client-dynamodb/dist-types/models/models_0";
import { Entry, Miss } from "./types/Events";
import axios, { AxiosResponse } from "axios";
import { ButtonGroup, Container, ToggleButton } from "react-bootstrap";
import NavBar from "./NavBar";
import {useNavigate, useSearchParams} from "react-router-dom";
import {isDST} from "./utils/time.ts";

type Response = {
    body: string,
    headers: {[key: string]: string},
    statusCode: number
}

type DataResponse = {
    candles: ChartCandle[],
    entriesAndMisses: Record<string, AttributeValue>[]
}

type ChartCandle = {
    x: Date,
    open: number,
    high: number,
    low: number,
    close: number,
    time: number,
    tooltip: string
}

function HomePage() {
    let hasRemovedBanner = false;
    const [minuteCandles, setMinuteCandles] = useState<ChartCandle[]>([]);
    const [chartData, setChartData] = useState<ChartCandle[] | undefined>(undefined);
    const [entries, setEntries] = useState<Entry[] | undefined>(undefined);
    const [misses, setMisses] = useState<Miss[] | undefined>(undefined);
    const [radioValue, setRadioValue] = useState(15);

    const stripLines: StripLineSettingsModel[] = entries?.map(entry => {
        return {
            start: new Date(entry.timestamp - 15 *60000),
            end: new Date(entry.timestamp + 15 *60000),
            text: `${entry.EntryType} Order`,
            color: entry.EntryType === "Long" ? "green" : "red",
            visible: true,
            opacity: 0.1,
            zIndex: "Behind"
        }
    }) ?? [];
    const primaryXAxis: AxisModel = {
        zoomFactor: 0.3,
        title: "Date (EST)",
        valueType: "DateTime",
        intervalType: "Days",
        crosshairTooltip: {enable: true},
        stripLines
    };
    const primaryYAxis = {title: "Price", crosshairTooltip: {enable: true}}
    const style = {textAlign: "center"};
    const legendSettings = {visible: true};
    const zoomSettings = {
        enableMouseWheelZooming: true,
        enablePinchZooming: true,
        enableSelectionZooming: true,
        mode: 'XY',
        enableScrollbar: true
    } as ZoomSettingsModel;
    const tooltip: TooltipSettingsModel = {enable: true, format: '${point.tooltip}'};
    const crosshair = {enable: true};
    const load = (args: { chart: { zoomModule: { isZoomed: boolean; }; }; }) => {
        args.chart.zoomModule.isZoomed = true;
    };
    const animation = { enable: true, duration: 1200, delay: 100 };

    useEffect(() => {
        if (!chartData || !entries || !misses) {
            getData();
        }

        if (!hasRemovedBanner) {
            setTimeout(() => {
                const divs = document.getElementsByTagName('div');
                for (const div of divs) {
                    for (const child of div.children) {
                        if (child.tagName === 'IMG') {
                            div.remove();
                            hasRemovedBanner = true;
                        }
                    }
                }
            }, 1500)
        }
    });

    async function getData(): Promise<void> {
        const resp: AxiosResponse<Response> = await axios.get('https://trf19bakrb.execute-api.us-east-1.amazonaws.com/prod/getBotData');
        const parsedResp: DataResponse = JSON.parse(resp.data.body);
        if (parsedResp.candles) {
            setMinuteCandles(parsedResp.candles)
            setChartData(convertMinuteToFifteenMinuteCandles(parsedResp.candles));
        }

        const entriesAndMisses: Record<string, AttributeValue>[] = parsedResp.entriesAndMisses;
        setEntries(entriesAndMisses.filter(value => value["Type"].S === "Entry").map(value => {
            return {
                timestamp: Number(value["timestamp"].N),
                Type: value["Type"].S ?? "Entry",
                EntryPrice: Number(value["EntryPrice"].N),
                StopLoss: Number(value["StopLoss"].N),
                TakeProfit: Number(value["TakeProfit"].N),
                GapTime: Number(value["GapTime"].N),
                EntryType: value["EntryType"].S
            }
        }).sort((a, b) => b.timestamp - a.timestamp));
        setMisses(entriesAndMisses.filter(value => value["Type"].S === "Miss").map(value => {
            return {
                timestamp: Number(value["timestamp"].N),
                Type: value["Type"].S ?? "Entry",
                Price: Number(value["Price"].N),
                MissType: value["MissType"].S,
                Reason: value["Reason"].S
            }
        }).sort((a, b) => b.timestamp - a.timestamp));
    }

    const convertMinuteToFiveMinuteCandles = (minuteCandles: ChartCandle[]): ChartCandle[] => {
        let count = 0;
        let firstCandleTime = 0;
        let high = Number.MIN_SAFE_INTEGER;
        let low = Number.MAX_SAFE_INTEGER;
        let open = 0;
        let close = 0;
        let time = 0;
        let x = new Date();
        let tooltip = "";

        const fiveMinuteCandles: ChartCandle[] = [];
        minuteCandles
            .sort((a, b) => b.time - a.time)
            .forEach(candle => {
                firstCandleTime = count === 0 ? candle.time : firstCandleTime;
                const isLastCandle = firstCandleTime - candle.time >= 5*60000;
                high = candle.high > high ? candle.high : high;
                low = candle.low < low ? candle.low : low;
                open = isLastCandle ? candle.open : open;
                close = count === 0 ? candle.close : close;
                time = count === 0 ? candle.time : time;
                x = count === 0 ? candle.x : x;
                tooltip = count === 0 ? candle.tooltip : tooltip;

                if (isLastCandle) {
                    fiveMinuteCandles.push({
                        x,
                        open,
                        high,
                        low,
                        close,
                        time,
                        tooltip
                    });
                    count = 0;
                    high = Number.MIN_SAFE_INTEGER;
                    low = Number.MAX_SAFE_INTEGER;
                    open = 0;
                    close = 0;
                    time = 0;
                } else {
                    count++;
                }
            });

        return fiveMinuteCandles.sort((a, b) => a.time - b.time);
    }

    const convertMinuteToFifteenMinuteCandles = (minuteCandles: ChartCandle[]): ChartCandle[] => {
        let count = 0;
        let firstCandleTime = 0;
        let high = Number.MIN_SAFE_INTEGER;
        let low = Number.MAX_SAFE_INTEGER;
        let open = 0;
        let close = 0;
        let time = 0;
        let x = new Date();
        let tooltip = "";

        const fifteenMinuteCandles: ChartCandle[] = [];
        minuteCandles
            .sort((a, b) => b.time - a.time)
            .forEach(candle => {
                firstCandleTime = count === 0 ? candle.time : firstCandleTime;
                const isLastCandle = firstCandleTime - candle.time >= 15*60000;
                high = candle.high > high ? candle.high : high;
                low = candle.low < low ? candle.low : low;
                open = isLastCandle ? candle.open : open;
                close = count === 0 ? candle.close : close;
                time = count === 0 ? candle.time : time;
                x = count === 0 ? candle.x : x;
                tooltip = count === 0 ? candle.tooltip : tooltip;

                if (isLastCandle) {
                    fifteenMinuteCandles.push({
                        x,
                        open,
                        high,
                        low,
                        close,
                        time,
                        tooltip
                    });
                    count = 0;
                    firstCandleTime = 0;
                    high = Number.MIN_SAFE_INTEGER;
                    low = Number.MAX_SAFE_INTEGER;
                    open = 0;
                    close = 0;
                    time = 0;
                } else {
                    count++;
                }
            });

        return fifteenMinuteCandles.sort((a, b) => a.time - b.time);
    }

    return (
        <Container data-bs-theme="dark">
            <NavBar />
            <Container className="chart-row" data-bs-theme="dark">
                {getChartComponent()}
            </Container>
            <Container className="table-row" data-bs-theme="dark">
                <h1>Misses</h1>
                {getMissesListViewComponent()}
            </Container>
            <Container className="table-row" data-bs-theme="dark">
                <h1>Orders</h1>
                {getEntriesListViewComponent()}
            </Container>
        </Container>
    );

    function getChartComponent() {
        if (chartData && chartData.length > 0) {
            return (
                <Container data-bs-theme="dark">
                    <ButtonGroup className="mb-2">
                        <ToggleButton
                            key={1}
                            id={`radio-1`}
                            type="radio"
                            variant="secondary"
                            name="15m"
                            value={15}
                            checked={radioValue === 15}
                            onChange={(e) => setChartRange(+e.currentTarget.value)}
                        >
                            15m
                        </ToggleButton>
                        <ToggleButton
                            key={2}
                            id={`radio-2`}
                            type="radio"
                            variant="secondary"
                            name="5m"
                            value={5}
                            checked={radioValue === 5}
                            onChange={(e) => setChartRange(+e.currentTarget.value)}
                        >
                            5m
                        </ToggleButton>
                        <ToggleButton
                            key={3}
                            id={`radio-3`}
                            type="radio"
                            variant="secondary"
                            name="1m"
                            value={1}
                            checked={radioValue === 1}
                            onChange={(e) => setChartRange(+e.currentTarget.value)}
                        >
                            1m
                        </ToggleButton>
                    </ButtonGroup>
                    <ChartComponent
                        id="es-chart"
                        title="ES Trading"
                        primaryXAxis={primaryXAxis}
                        primaryYAxis={primaryYAxis}
                        style={style}
                        legendSettings={legendSettings}
                        tooltip={tooltip}
                        zoomSettings={zoomSettings}
                        crosshair={crosshair}
                        load={load}
                    >
                        <Inject
                            services={[CandleSeries, DateTime, StripLine, Legend, Tooltip, Zoom, ScrollBar, Crosshair]}></Inject>
                        <SeriesCollectionDirective>
                            <SeriesDirective
                                type="Candle"
                                name="ESM4"
                                dataSource={chartData}
                                xName="x"
                                yName="low"
                                high="high"
                                low="low"
                                open="open"
                                close="close"
                                tooltipMappingName="tooltip"
                                animation={animation}
                            ></SeriesDirective>
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </Container>
            )
        } else if (chartData === undefined) {
            return (
                <div className="loader"></div>
            )
        } else if (chartData?.length === 0) {
            return (
                <h2>No data available</h2>
            )
        }
    }

    function getMissesListViewComponent() {
        if (misses && misses.length > 0) {
            return (
                <table className="table">
                    <thead>
                        <tr>
                            <th className="table-column">Date</th>
                            <th className="table-column">Price</th>
                            <th className="table-column">Miss Type</th>
                            <th className="table-column">Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        {misses.map((val, key) => {
                            const timeZoneOffset = isDST(new Date()) ?  -4 : -5;
                            const adjustedTime = new Date(val.timestamp + (timeZoneOffset * 3600000));
                            return (
                                <tr key={key}>
                                    <td className="table-column">{adjustedTime.toISOString()}</td>
                                    <td className="table-column">{`$${val.Price}`}</td>
                                    <td className="table-column">{val.MissType}</td>
                                    <td className="table-column">{val.Reason}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        } else if (misses === undefined) {
            return (
                <div className="loader"></div>
            )
        } else if (misses?.length === 0) {
            return (
                <h2>No Misses to Show</h2>
            )
        }
    }

    function getEntriesListViewComponent() {
        if (entries && entries.length > 0) {
            return (
                <table className="table">
                    <thead>
                        <tr>
                            <th className="table-column">Date</th>
                            <th className="table-column">Order Price</th>
                            <th className="table-column">Stop Loss</th>
                            <th className="table-column">Take Profit</th>
                            <th className="table-column">Gap Time</th>
                            <th className="table-column">Order Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {entries.map((val, key) => {
                            const timeZoneOffset = isDST(new Date()) ?  -4 : -5;
                            const adjustedTime = new Date(val.timestamp + (timeZoneOffset * 3600000));
                            const adjustedGapTime = new Date(val.GapTime + (timeZoneOffset * 3600000));
                            return (
                                <tr key={key}>
                                    <td className="table-column">{adjustedTime.toISOString()}</td>
                                    <td className="table-column">{`$${val.EntryPrice}`}</td>
                                    <td className="table-column">{`$${val.StopLoss}`}</td>
                                    <td className="table-column">{`$${val.TakeProfit}`}</td>
                                    <td className="table-column">{adjustedGapTime.toISOString()}</td>
                                    <td className="table-column">{val.EntryType}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        } else if (entries === undefined) {
            return (
                <div className="loader"></div>
            )
        } else if (entries?.length === 0) {
            return (
                <h2>No Entries to Show</h2>
            )
        }
    }

    function setChartRange(range: number) {
        setRadioValue(range);
        switch (range) {
            case 15:
                setChartData(convertMinuteToFifteenMinuteCandles(minuteCandles));
                break;
            case 5:
                setChartData(convertMinuteToFiveMinuteCandles(minuteCandles));
                break;
            case 1:
                setChartData(minuteCandles);
                break;
        }
    }
}

export default HomePage;
